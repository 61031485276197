import 'dotenv'

// api url
const Domains = {
  development: process.env.API_URL_DEV,
  test: process.env.API_URL_TEST,
  production: process.env.API_URL_PROD,
}

// configurables
const configurables = {
  IS_QUOTE_OPTIMISATION_ON: false
}

// web domains
const WebDomains = {
  development: process.env.WEB_DOMAIN_DEV,
  test: process.env.WEB_DOMAIN_TEST,
  production: process.env.WEB_DOMAIN_PROD,
}

const AnalyticsDomain = process.env.ANALYTICS_DOMAIN_URL

const Cdns = {
  development: process.env.CDN_URL_DEV,
  test: process.env.CDN_URL_TEST,
  production: process.env.CDN_URL_PROD,
}

const Regex = {
  email: /^[a-zA-Z0-9][a-zA-Z0-9_.+\-]*@[a-zA-Z0-9]+([-.][a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/,
  noWhitespace: /^\S*$/,
  numeric: /^\d+$/,
  alphaSpaceDash: /^[a-z -]+$/i,
  alphanumericSpace: /^[a-zA-Z0-9 ]+$/i,
  alphabets: /^[a-zA-Z]+$/,
  alphanumeric: /^[a-zA-Z0-9]*$/,
  vin: /[A-HJ-NPR-Z0-9]{17}/,
  zaId: /^(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))((\d{4})(0|1)(\d{2}))/,
  zaCompanyRegistrationNumer: /((19|20)[\d]{2}\/[\d]{6}\/[\d]{2})/,
  zaVatNumber: /^[4][0-9]{9}$/,
  engineNo: /[A-HJ-NPR-Z0-9]{1,12}/,
  streetName: /^[a-zA-Z ]{3,35}$/,
  cellNumber: /^0[6-8]\d{8}$/,
  landLineNumber: /^0[1-8]\d{8}$/,
}

const BLOCKED_REFFERER_URLS = [
  process.env.BLOCKED_REFFERER_URL_1,
  process.env.BLOCKED_REFFERER_URL_2,
]

/* microservices */
const MicroServices = {
  Motor: {
    development: process.env.MOTOR_URL_DEV,
    test: process.env.MOTOR_URL_TEST,
    production: process.env.MOTOR_URL_PROD,
  },
  Dealership: {
    development: process.env.DEALERSHIP_URL_DEV,
    test: process.env.DEALERSHIP_URL_TEST,
    production: process.env.DEALERSHIP_URL_PROD,
  },
  Recollections: {
    development: process.env.RECOLLECTIONS_URL_DEV,
    test: process.env.RECOLLECTIONS_URL_TEST,
    production: process.env.RECOLLECTIONS_URL_PROD,
  },
}

const VehicleColours = [
  'Gold',
  'White',
  'Blue',
  'Silver',
  'Purple',
  'Green',
  'Red',
  'Black',
  'Yellow',
  'Brown',
  'Grey',
  'Orange',
]

const VehicleStatuses = [
  {
    description: 'New',
    value: 'New',
  },
  {
    description: 'Second Hand/Used',
    value: 'Used',
  },
]

const MaritalStatuses = [
  'Single',
  // 'Unmarried',
  'Married',
  'Living Together',
  'Divorced',
  'Widow',
  'Annulment'
]

const EmploymentStatuses = [
  {
    description: 'Employed',
    value: 'Employed',
  },
  {
    description: 'Unemployed',
    value: 'Unemployed',
  },
  {
    description: 'Retired',
    value: 'RetiredAndNotGainfullyEmployed',
  },
  {
    description: 'Retired but earning active income',
    value: 'Retired',
  },
]

const VehicleUses = [
  {
    description: 'Private',
    value: 'domestic'
  },
  {
    description: 'Professional',
    value: 'business'
  },
  {
    description: 'Commercial',
    value: 'commercial'
  }
]

const VehicleAnnualMileages = [
  {
    description: '0 - 15 000 km',
    value: '1'
  },
  {
    description: '15000 km - 30 000 km',
    value: '2'
  },
  {
    description: '30 001 km - 45 000 km',
    value: '3'
  },
  {
    description: '45001 km - 60 000 km',
    value: '4'
  },
  {
    description: 'Over 60 000',
    value: '5'
  },
]

const VehicleDisabilities = [
  {
    description: 'Yes',
    value: 'Y'
  },
  {
    description: 'No',
    value: 'N'
  },
]

const CompanyStructures = [
  'Registered Company',
  'Sole Proprietor'
]

const LicenseCodes = [
  'B: Motor vehicle that does not exceed 3500kgs.',
  'C: Motor vehicle that exceeds 16000kgs.',
  'C1: Motor vehicle between 3500kgs and 16000kgs',
  'EB: Articulated motor vehicle that does not exceed 3500kgs',
  'EC: Articulated motor vehicle that exceeds 16000kgs',
  'EC1: Articulated motor between 3500kgs and 16000kgs',
]

const LicenseCodesMap = {
  B: 'B: Motor vehicle that does not exceed 3500kgs.',
  C: 'C: Motor vehicle that exceeds 16000kgs.',
  C1: 'C1: Motor vehicle between 3500kgs and 16000kgs',
  EB: 'EB: Articulated motor vehicle that does not exceed 3500kgs',
  EC: 'EC: Articulated motor vehicle that exceeds 16000kgs',
  EC1: 'EC1: Articulated motor between 3500kgs and 16000kgs',
}

const LicenseRestrictions = [
  {
    description: 'None',
    value: 'none',
  },
  {
    description: 'Automatic cars only',
    value: 'AUTO',
  },
  {
    description: 'Disabled',
    value: 'DISA',
  }
]

const AccountTypes = ['Current', 'Savings', 'Transmission']

const RelationshipTypesRegularDriver = {
  A: 'They are my spouse',
  A1: 'They are my parent/step-parent',
  // F3: 'They are my parent-inlaw',
  A2: 'They are my child/stepchild living with me, the policyholder',
  // D4: 'They are my family member living with me, the policyholder',
  D1: 'We are living together',
  A3: 'They are my allocated driver (owner is incapacitated)',
  G: 'They are other persons to me',
}

const RelationshipTypesOwner = {
  A: 'They are my spouse',
  A1: 'They are my parent/step-parent',
  // F3: 'They are my parent-inlaw',
  A2: 'They are my child/stepchild living with me, the policyholder',
  // D4: 'They are my family member living with me, the policyholder',
  D1: 'We are living together',
  G: 'They are other persons to me',
}

const RelationshipTypesBankAccountHolder = {
  77: 'They are my spouse',
  A1: 'They are my parent/step-parent',
  F3: 'They are my parent-inlaw',
  A2: 'They are my child/stepchild living with me, the policyholder',
  D4: 'They are my family member living with me, the policyholder',
  D1: 'We are living together',
  G: 'They are other persons to me',
}

const FinanceHouses = {
  'AA Asset Finance (SA Motor Admin)': 17,
  'ABSA': 1,
  'African Bank': 27,
  'Albaraka Bank': 35,
  'Audi Financial Services': 28,
  'Bidvest': 34,
  'BMW Financial Services': 12,
  'Daimler Chrysler Finance': 15,
  'Ford Credit': 2,
  'IEMAS Financial Services': 32,
  'IEMAS Motor Finance': 21,
  'Imas': 26,
  'Imperial Bank': 18,
  'Investec': 19,
  'Ithala Development Finance Corporation': 29,
  'Ithala SOC Limited': 30,
  'Lexus Finance House': 22,
  'Marquis Finance': 33,
  'MFC': 13,
  'MLS': 3,
  'Mercantile Bank': 16,
  'Mercedes Benz Finance': 14,
  'Nedbank': 4,
  'Nissan Finance': 8,
  'Old Mutual': 5,
  'Standard Bank': 24,
  'Stannic': 6,
  'Themba Bank': 25,
  'Toyota Finance': 9,
  'U Buy Motor': 20,
  'Unconfirmed': 'U',
  'VW Financial Services': 10,
  'Wesbank': 7,
  'Wesbank Corporate': 11,
}

const ExcessCodes = {
  0: 100,
  2000: 110,
  4500: 175,
  6200: 200,
  8100: 210,
  9200: 220,
  10350: 230,
  11600: 240,
  12750: 250,
  13900: 260,
  15000: 270,
  16250: 280,
  17400: 290,
  17900: 300
}

const PreviousLossesDamageTypes = {
  AccidentalDamage: 'GMLT',
  DamageToOthers: '103',
  Fire: '108',
  MotherNature: '105',
  TheftHijacking: '107'
}

const ClaimRejectionReasons = [
  {
    description: 'Late notification/No cover/Policy exclusion',
    value: 'PEX',
  },
  {
    description: 'Fraud/Potential fraud/Misrepresentation/Moral risk',
    value: 'MND',
  },
  {
    description: 'Other/Premium unpaid',
    value: 'OTH',
  },
]

const NightTimeStructures = [
  {
    description: 'Carport',
    value: 'C',
  },
  {
    description: 'Garage',
    value: 'G',
  },
  {
    description: 'In the open',
    value: 'O',
  },
  {
    description: 'Not sure yet',
    value: 'U',
  },
]

const NightTimeSituations = [
  {
    description: 'Standalone house',
    value: 'R',
  },
  {
    description: 'Plot/Small holding',
    value: 'S',
  },
  {
    description: 'Complex/Gated estate',
    value: 'E',
  },
  {
    description: 'Unconfirmed',
    value: 'U',
  },
]

const NightTimeSecurityOptions = [
  {
    description: 'Yes',
    value: 'Y',
  },
  {
    description: 'No',
    value: 'N',
  },
  {
    description: 'Not sure',
    value: 'U'
  }
]

const NightTimeSecurityMap = {
  'U': {
    nightTimeAccessControl: 'Unknown',
    nightTimeSecurityGuard: 'Unknown'
  },
  'ACSG': {
    nightTimeAccessControl: 'Yes',
    nightTimeSecurityGuard: 'YES'
  },
  'ACNSG': {
    nightTimeAccessControl: 'Yes',
    nightTimeSecurityGuard: 'No'
  },
  'SG': {
    nightTimeAccessControl: 'No',
    nightTimeSecurityGuard: 'Yes'
  },
  'NACNSG': {
    nightTimeAccessControl: 'No',
    nightTimeSecurityGuard: 'NO'
  }
}

const DefaultCssSelectors = {
  idName: 'ThisIsADefault'
}

const formatValidationMessage = (field) => {
  return `Please enter a valid ${field}`
}

const formatSelectionValidationMessage = (field) => {
  return `Please select a ${field}`
}

const genericValidationMessage = () => {
  return 'Please select an option'
}

const genericNotificationMessage = (field) => {
  return `Please note: ${field}`
}

const validationMessages = {
  creditshotFall: genericNotificationMessage('You do not qualify for credit shortfall as the vehicle retail value is greater that the loan amount'),
  residualAmount: formatValidationMessage('Residual Payment Amount'),
  loanAmount: formatValidationMessage('Loan Amount'),
  monthlyInstallment: formatValidationMessage('Monthly Installment'),
  loanPeriod: formatValidationMessage('Loan Period'),
  numberPlate: formatValidationMessage('Number Plate'),
  contactNumber: formatValidationMessage('Contact Number (e.g 0831231231)'),
  email: formatValidationMessage('Email Address'),
  lastName: formatValidationMessage('last name'),
  firstName: formatValidationMessage('first name'),
  fullName: formatValidationMessage('Name & Surname'),
  vinNumber: formatValidationMessage('Vin Number'),
  engineNumber:formatValidationMessage('Engine Number'),
  mmCode: formatValidationMessage('MM Code'),
  carMake: formatSelectionValidationMessage('Make'),
  carYear: formatValidationMessage('Year'),
  carModel: formatSelectionValidationMessage('Model that matches Make and Year'),
  carColor: formatSelectionValidationMessage('Colour'),
  carStatus: formatSelectionValidationMessage('Status'),
  carDaytimeAddress: formatSelectionValidationMessage('Day Address'),
  carNighttimeAddress: formatSelectionValidationMessage('Home Address'),
  carNightSituation: genericValidationMessage(),
  carNightSituation: genericValidationMessage(),
  carAccessControl: genericValidationMessage(),
  carSecurityGuard: genericValidationMessage(),
  carUse: genericValidationMessage(),
  carOwner: genericValidationMessage(),
  relationshipStatus: genericValidationMessage(),
  idNumber: formatValidationMessage('ID Number'),
  maritalStatus: genericValidationMessage(),
  employmentStatus: genericValidationMessage(),
  licenseCode: genericValidationMessage(),
  licenseRestriction: genericValidationMessage(),
  address: formatSelectionValidationMessage('Address'),
  continuousCover: formatValidationMessage('Number, and make sure it is less than the number of years you have held a driver\'s license.'),
  continuousOwnerCover: formatValidationMessage('Number, and make sure it is less than your current age'),
  historicClaims: genericValidationMessage(),
  licenseIssueDate: formatSelectionValidationMessage('license issue date, it is required and make sure you were 18 years old on this date'),
  regularDriver: genericValidationMessage(),
  accessories: genericValidationMessage(),
  accessoriesAmount: formatValidationMessage('Number'),
  financed: genericValidationMessage(),
  financeHouse: formatValidationMessage('Finance House'),
  svr: genericValidationMessage(),
  disability: genericValidationMessage(),
  disabilityDescription: formatValidationMessage('Description'),
  daynightAddress: genericValidationMessage(),
  addressComparison: genericValidationMessage(),
  coverStartDate: formatSelectionValidationMessage('Date'),
  claimRejected: genericValidationMessage(),
  claimRejectedReason: genericValidationMessage(),
  claimDescription: formatValidationMessage('Description (minimum 10 characters)'),
  claimCost: formatValidationMessage('Number'),
  claimCostMaxLimit: genericNotificationMessage('The loss value cannot exceed R10 million'),
  claimYear: formatValidationMessage('Year'),
  streetName: formatValidationMessage('Street name'),
  streetNumber: formatValidationMessage('Street number'),
  postalCode: formatValidationMessage('Postal code'),
  suburb: formatValidationMessage('Suburb'),
  userId: formatValidationMessage('user ID'),
  claimType: formatValidationMessage('Claim Type'),
  claimDate: formatValidationMessage('Claim Date, that is not in the future'),
  claimTime: formatValidationMessage('Claim Time, that is not in the future'),
  lossType: formatValidationMessage('Loss Type'),
  causeType: formatValidationMessage('Cause Type'),
  theftClaimCaseNumber: formatValidationMessage('SAPS Case Number'),
  theftClaimPoliceStation: formatValidationMessage('Police Station'),
  damageClaimDamageAmount: formatValidationMessage('Damage Amount'),
  claimIncidentAddress: formatValidationMessage('Incident Address'),
  claimIncidentDescription: formatValidationMessage('Incident Description'),
  bankingDetails: 'Your bank account details do not seem to be valid. Please double-check that your account number has been entered correctly, ensure the correct account type has been selected, and then try again.',
}

const carHireOptions = {
  A: 'Mini',
  B: 'Economy Hatchback',
  D: 'Economy Sedan',
  E: 'Economy Sedan Automatic',
  K: 'Standard SUV 2WD',
}

const vehicleCategoriesThatCanSelectSUVForCarHire = ['D/C', 'D/S', 'P/V', 'MPV', 'S/C', 'SAV', 'SUV']

const vehiclePriceLimit = 1200000

const errorFetchingPremium = 'Could not calculate your premium'

// Drive Less Get Blessed discount
// Should apply to base premium + options
// but not to sasria
const LOW_MILEAGE_PERCENTAGE = 0.71

// String representing how much of a refund
// the user will get for Drive Less Get Blessed
// for display purposes
const REFUND_PERCENTAGE_STRING = '30'

export {
  Domains,
  WebDomains,
  AnalyticsDomain,
  Cdns,
  Regex,
  MicroServices,
  VehicleColours,
  VehicleStatuses,
  MaritalStatuses,
  CompanyStructures,
  LicenseCodes,
  LicenseCodesMap,
  AccountTypes,
  RelationshipTypesRegularDriver,
  RelationshipTypesOwner,
  RelationshipTypesBankAccountHolder,
  FinanceHouses,
  EmploymentStatuses,
  ExcessCodes,
  VehicleUses,
  VehicleAnnualMileages,
  VehicleDisabilities,
  PreviousLossesDamageTypes,
  LicenseRestrictions,
  ClaimRejectionReasons,
  NightTimeStructures,
  NightTimeSituations,
  NightTimeSecurityOptions,
  NightTimeSecurityMap,
  DefaultCssSelectors,
  validationMessages,
  configurables,
  carHireOptions,
  vehicleCategoriesThatCanSelectSUVForCarHire,
  BLOCKED_REFFERER_URLS,
  vehiclePriceLimit,
  errorFetchingPremium,
  LOW_MILEAGE_PERCENTAGE,
  REFUND_PERCENTAGE_STRING
}
